import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import './Header.css';

Modal.setAppElement('#root');

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 430);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 430);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // メニュー項目を選択した後にメニューを閉じる処理
  const closeMenu = () => {
    setMenuOpen(false);
    window.scrollTo(0, 0); // ページの一番上へスクロール
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/" className="logo-link" onClick={closeMenu}>
          <img src="/images/n_1.png" alt="会社のロゴ" className="topLogo" />
        </Link>
      </div>
      {isMobile && (
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={menuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      )}
      {isMobile ? (
        <Modal
          isOpen={menuOpen}
          onRequestClose={toggleMenu}
          className="nav-modal"
          overlayClassName="nav-modal-overlay"
        >
          <ul className="nav-links">
            <li onClick={closeMenu}>
              <Link to="/">Top</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/CompanyPage">会社案内</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/service">サービス紹介</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/contact">お問い合わせ</Link>
            </li>
          </ul>
        </Modal>
      ) : (
        <nav>
          <ul className="nav-links">
            <li onClick={closeMenu}>
              <Link to="/">Top</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/CompanyPage">会社案内</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/service">サービス紹介</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/contact">お問い合わせ</Link>
            </li>
            <li onClick={closeMenu} className="helpful-materials-button">
              <Link to="/ContentListPage">お役立ち資料</Link>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default Header;

// news1.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NewsDetailPage.css';

function NewsDetailPage() {
  const navigate = useNavigate();

  const handleBackToTop = () => {
    navigate('/');
  };

  return (
    <div className="news-detail-container">
      <div>
        <h2>2022/11/4</h2>
        <p>
          きらぼし銀行アクセラレーションプログラム「KicSpace
          Accelerator」に採択されました！
        </p>
        <a
          href="https://kicspace.com/accelerator/information/366/"
          target="_blank"
          rel="noopener noreferrer"
        >
          詳細を見る
        </a>
      </div>
      <button onClick={handleBackToTop} className="back-to-top-btn">
        トップページに戻る
      </button>
    </div>
  );
}

export default NewsDetailPage; // 修正されたエクスポート名

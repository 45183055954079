import React from 'react';
import './CompanyPage.css'; // スタイルシートのパスを確認してください

function CompanyPage() {
  return (
    <div>
      <div className="company">
        <div className="mvv">
          <div className="mission">
            <p className="title">-MISSION-</p>
            <p className="highlight">
              就職活動を<span className="red-text">なくす　　　　　　　</span>
            </p>
          </div>
          <div className="vision">
            <p className="title">-VISION-</p>
            <p className="highlight">
              キャリアに<span className="red-text">無限の可能性</span>を作る
            </p>
          </div>
          <div className="value">
            <p className="title">-VALUE-</p>
            <p className="highlight">
              <span className="red-text">"すべて"</span>は一人の未来のために
              <br />
              <span className="red-text">"おもい"</span>は行動で示す
              <br />
              <span className="red-text">"できない"</span>は楽しい
            </p>
          </div>
        </div>
      </div>

      <div className="company-info">
        <h2>-COMPANY INFO-</h2>
        <div className="info-item">
          <span className="info-title">社名</span>
          <span className="info-content">PortRay株式会社</span>
        </div>
        <hr />
        <div className="info-item">
          <span className="info-title">事業内容</span>
          <span className="info-content">
            転職のセンター試験
            <br />
            動画メディア
            <br />
            有料職業紹介事業
          </span>
        </div>
        <hr />
        <div className="info-item">
          <span className="info-title">設立</span>
          <span className="info-content">2019年3月</span>
        </div>
        <hr />
        <div className="info-item">
          <span className="info-title">代表者</span>
          <span className="info-content">代表取締役　鹿野翔太</span>
        </div>
        <hr />
        <div className="info-item">
          <span className="info-title">住所　　　　　　　</span>
          <span className="info-content">
            〒107-0061　東京都港区北青山２丁目１２番８号　BIZSMART
          </span>
        </div>
        <hr />
        <div className="info-item">
          <span className="info-title">事業許可番号</span>
          <span className="info-content">
            有料職業紹介事業許可番号　13-ユ-314535
          </span>
        </div>
      </div>
    </div>
  );
}

export default CompanyPage;

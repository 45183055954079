import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NewsDetailPage.css';

function NewsDetailPage() {
  const navigate = useNavigate();

  const handleBackToTop = () => {
    navigate('/');
  };

  return (
    <div className="news-detail-container">
      <div>
        <h2>2024/2/2</h2>
        <p>CAREER BIBLE様に掲載いただきました</p>
        <p>
          【徹底比較】転職エージェントおすすめ45選【2024】業界・職種別にも紹介！
        </p>
        <a
          href="https://nexer.co.jp/career-bible/domestic-company_jobchange/"
          target="_blank"
          rel="noopener noreferrer"
        >
          詳細を見る
        </a>
        <p>
          20代向けハイクラス層のおすすめ転職エージェント14選！求められるスキルとは？
        </p>
        <a
          href="https://nexer.co.jp/career-bible/high-class-20s/"
          target="_blank"
          rel="noopener noreferrer"
        >
          詳細を見る
        </a>
        <p>
          20代の平均年収や手取りは？大卒や高卒での違いは？男女別や年収アップのコツなども解説！
        </p>
        <a
          href="https://nexer.co.jp/career-bible/20-average-annual-income/"
          target="_blank"
          rel="noopener noreferrer"
        >
          詳細を見る
        </a>
      </div>
      <button onClick={handleBackToTop} className="back-to-top-btn">
        トップページに戻る
      </button>
    </div>
  );
}

export default NewsDetailPage;

import React from 'react';
import './Service.css';

function Service() {
  return (
    <div>
      <div className="content-column">
        <div className="content-row">
          <p className="service-title">転職のセンター試験</p>
          <img src={'/images/n_4.png'} alt="画像説明" className="serviceLogo" />
        </div>
        <p className="text-subtitle">
          1次面接通過レベルの人材とだけ出会える採用サービス
        </p>
      </div>

      <div className="content-row image-left">
        <img
          src={'/images/ServiceImg2.png'}
          alt="画像説明"
          className="image-block"
        />
        <div className="text-container">
          <p className="text-title">
            スコアリングによる
            <br />
            センター試験型採用サービス
          </p>
          <p className="text-description">
            会ってみないと詳細な経験・スキルが分からない。今ある採用サービスではその
            課題から1次面接の70％が採用基準に満たない人を落とすために行われています。
            事前に詳細な評価が分かれば本来行わなくて済んだ面接をなくせるのではないか。
            本当に出会うべき企業と求職者の最大化。その実現のために転職のセンター試験
            OSUMITSUKIは生まれました。<br></br>
            <br></br>
            OSUMITSUKIでは登録ユーザー全てに共通の構造化面接・スコアリングを行い、
            既存の採用サービスでは分からなかった詳細な経験・スキルまで判定。企業は採
            用要件に合わせ1次面接の合格ボーダーを設定するだけで1次面接合格レベルの人
            材からのみ応募が届く業界唯一の採用サービスです。
          </p>
        </div>
      </div>

      <div className="content-row text-right">
        <div className="text-container">
          <p className="text-title">
            50以上の評価項目から
            <br />
            詳細な経験・スキルを評価
          </p>
          <p className="text-description">
            コミュニケーション能力・リーダーシップ・論理的思考力などのパー
            ソナルスキルから新規開拓力・行動力・クロージング力のような営業
            スキルまで。OSUMITSUKIの登録ユーザーは全員が面接を経て50以上の評価項目×5段階でスコアリングされます。
            <br></br>
            <br></br>
            企業が設定する1次面接の合格ボーダーも項目毎に全てカスタマイズ 可能。
          </p>
        </div>
        <img
          src={'/images/ServiceImg.png'}
          alt="画像説明"
          className="image-block"
        />
      </div>

      <div className="content-column">
        <div className="content-row">
          <p className="service-title">転職のセンター試験</p>
          <img src={'/images/n_4.png'} alt="画像説明" className="serviceLogo" />
        </div>
        <a href="https://osumitsuki-b.studio.site/" className="more-button1">
          もっと見る
        </a>
      </div>

      <div className="spacer-box"></div>
    </div>
  );
}

export default Service;

import React from 'react';
import { Link } from 'react-router-dom';
import './ContentListPage.css'; // CSSファイルをインポート

// 仮のコンテンツデータ（画像URLを追加）
const contents = [
  {
    id: 1,
    title: '転職者の本音！',
    description: '8割が応募したいと答える応募動機とは？',
    imageUrl: '/images/WP1.jpg', 
  },
  {
    id: 2,
    title: '面接官は"面接"が出来ているのか',
    description: '上場企業の面接官に聞いた面接の実態とは？',
    imageUrl: '/images/WP2.jpg',
  },
  // {
  //   id: 3,
  //   title: 'Content 2',
  //   description: 'This is the detail of content 2.',
  //   imageUrl: 'https://placekitten.com/200/200',
  // },
  // {
  //   id: 2,
  //   title: 'Content 2',
  //   description: 'This is the detail of content 2.',
  //   imageUrl: 'https://placekitten.com/200/200',
  // },
];

function ContentListPage() {
  return (
    <div>
      <div className="content-header">
        <h1>お役立ち資料</h1>
      </div>
      <div className="content-list">
        {contents.map((content) => (
          <div key={content.id} className="content-box">
            <Link to={`/content/${content.id}`} className="content-link">
              <img
                src={content.imageUrl}
                alt={content.title}
                className="content-image"
              />
              <div className="content-text">
                <h2>{content.title}</h2>
                <p>{content.description}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ContentListPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './ContentDetailPage.css';
import { firestore } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

// 仮の詳細データを更新し、画像URLとダウンロードURLを追加
const contentDetails = {
  1: {
    title: '「受かりやすさ」が分かれば踏み出す、8割の意外な真実',
    feed: '転職のセンター試験「OSUMITSUKI」を展開するPortRay株式会社（本社：東京都港区、代表取締役：鹿野翔太）は、IT企業または広告代理店に勤務しており、転職活動をしていないが、現在の職場に満足していない20～30代の営業・営業企画職122名を対象に、転職潜在層に関する実態調査を実施しましたので、お知らせいたします。',
    imageUrl: '/images/WP1.jpg', // 画像URLを追加
    downloadUrl: 'https://44599488.fs1.hubspotusercontent-na1.net/hubfs/44599488/%E8%BB%A2%E8%81%B7%E6%BD%9C%E5%9C%A8%E5%B1%A4%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%AE%9F%E6%85%8B%E8%AA%BF%E6%9F%BB%E3%80%90PortRay%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%80%91.pdf', // ダウンロードURLを追加
  },
  2: {
    title: '実は面接が下手な日本の上場企業',
    feed: '転職のセンター試験「OSUMITSUKI」を展開するPortRay株式会社（本社：東京都港区、代表取締役：鹿野翔太）は、上場企業の中途採用で一次面接の面接官の経験がある会社員110名を対象に、上場企業の中途採用の一次面接に関する実態調査を実施しましたので、お知らせいたします。',
    imageUrl: '/images/WP2.jpg', // 画像URLを追加
    downloadUrl: 'https://44599488.fs1.hubspotusercontent-na1.net/hubfs/44599488/%E4%B8%8A%E5%A0%B4%E4%BC%81%E6%A5%AD%E3%81%AE%E4%B8%80%E6%AC%A1%E9%9D%A2%E6%8E%A5%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%AE%9F%E6%85%8B%E8%AA%BF%E6%9F%BB%E3%80%90PortRay%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%80%91.pdf', // ダウンロードURLを追加
  },
  // 他のコンテンツの詳細を追加...
};


function ContentDetailPage() {
  const [formData, setFormData] = useState({ companyName: '', name: '', email: '', phone: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formError, setFormError] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (isSubmitting || validationError !== '') {
      setModalMessage(validationError); // エラーメッセージをモーダルに設定
      setShowModal(true); // エラーメッセージを表示するモーダルを表示
      return;
    }
    setIsSubmitting(true);
  

    try {
      // 選択されたコンテンツのタイトルをformDataに追加
      const selectedContentTitle = contentDetails[id]?.title || '';
      const docRef = await addDoc(collection(firestore, 'Document'), {
        ...formData,
        title: selectedContentTitle, // タイトルを追加
      });
      console.log('Document written with ID: ', docRef.id);
  
      // 特定のURLにリンクするコード
      const downloadUrl = contentDetails[id]?.downloadUrl || '';
      window.open(downloadUrl, '_blank');
      
      // 処理が完了した時のモーダル表示
      setModalMessage('資料のダウンロードありがとうございます。OSUMITSUKIをよろしくお願い致します。');
      setShowModal(true);
      setFormData({ companyName: '', name: '', email: '', phone: '' }); // フォームのリセット
      setFormError('');
    } catch (error) {
      console.error('Error adding document: ', error);
      // エラーが発生した場合のモーダル表示
      setModalMessage('送信中にエラーが発生しました。');
      setShowModal(true);
      setFormError('送信中にエラーが発生しました。');
    }
    
    finally {
      setIsSubmitting(false);
    }
  };

  let { id } = useParams();
  const content = contentDetails[id];

  useEffect(() => {
    return () => {
      setIsSubmitting(false); // コンポーネントのアンマウント時にisSubmittingをリセット
    };
  }, []);

  const validateForm = () => {
    if (!formData.companyName) {
      return '会社名を入力してください。';
    }
    if (!formData.name) {
      return '氏名を入力してください。';
    }
    if (!formData.email) {
      return 'メールアドレスを入力してください。';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      return 'メールアドレスの形式が正しくありません。';
    }
    if (!formData.phone) {
      return '電話番号を入力してください。';
    } else if (!/^\d+$/.test(formData.phone)) {
      return '電話番号は数値のみで入力してください。';
    }
    return '';
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const closePopup = () => {
    setShowModal(false);
  };

  if (!content) {
    return <div>Content not found</div>;
  }

  return (
    <div className="detail-container">
      <div className="detail-content">
        {content.imageUrl && (
          <img src={content.imageUrl} alt="Content" className="detail-image" />
        )}
        <h1>{content.title}</h1>
        <p>{content.feed}</p>
      </div>
      <div className="form-container">
        <h2>資料のダウンロードはこちらから</h2>
        <p>情報を入力していただくと資料がダウンロード可能です</p>
        {formError && <p className="form-error">{formError}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="companyName">会社名:</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">氏名:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">メールアドレス:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">電話番号:</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <button type="submit" disabled={isSubmitting}>資料ダウンロード</button>
        </form>
      </div>
      {showModal && (
        <div className="popup">
          <div className="modal-content">
            <p>{modalMessage}</p>
            <button onClick={closePopup}>閉じる</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentDetailPage;
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  // リンククリック時にページのトップにスクロールする関数
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-box">
          <h3>会社案内</h3>
          <div className="footer-links">
            <Link to="/CompanyPage" onClick={handleClick}>
              会社概要
            </Link>
          </div>
        </div>
        <div className="footer-box">
          <h3>サービス紹介</h3>
          <div className="footer-links">
            <Link to="/service" onClick={handleClick}>
              転職のセンター試験 OSUMITSUKI
            </Link>
          </div>
        </div>
        <div className="footer-box">
          <h3>お問合せ</h3>
          <div className="footer-links">
            <Link to="/contact" onClick={handleClick}>
              お問い合わせフォーム
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-logo">
        <img src="images/PortRay_ロゴ.png" alt="PortRayロゴ" />
        <p>COPYRIGHT PortRay INC. ALL RIGHTS RESERVED.</p>
      </div>
    </footer>
  );
}

export default Footer;

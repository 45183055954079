import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import HomePage from './HomePage';
import CompanyPage from './CompanyPage';
import ContactForm from './ContactForm';
import Service from './Service';
import ContentListPage from './ContentListPage';
import ContentDetailPage from './ContentDetailPage';
import NewsDetailPage1 from './news1'; // インポートを修正
import NewsDetailPage2 from './news2';
import NewsDetailPage3 from './news3';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/service" element={<Service />} />
          <Route path="/CompanyPage" element={<CompanyPage />} />
          <Route path="/ContentListPage" element={<ContentListPage />} />
          <Route path="/ContentDetailPage" element={<ContentDetailPage />} />
          <Route path="/news/1" element={<NewsDetailPage1 />} />{' '}
          <Route path="/news/2" element={<NewsDetailPage2 />} />
          <Route path="/news/3" element={<NewsDetailPage3 />} />
          <Route path="/content/:id" element={<ContentDetailPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NewsDetailPage.css';

function NewsDetailPage() {
  const navigate = useNavigate();

  const handleBackToTop = () => {
    navigate('/');
  };

  return (
    <div className="news-detail-container">
      <div>
        <h2>2024/2/21</h2>
        <p>Lumii Discover様に掲載いただきました</p>
        <a
          href="https://lumii.co.jp/discover/"
          target="_blank"
          rel="noopener noreferrer"
        >
          詳細を見る
        </a>
      </div>
      <button onClick={handleBackToTop} className="back-to-top-btn">
        トップページに戻る
      </button>
    </div>
  );
}

export default NewsDetailPage;

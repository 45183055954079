import React, { useState, useEffect } from 'react'; // useState と useEffect をインポート
import './HomePage.css';

const newsData = [
  {
    no: '7',
    title: '2024/4/22',
    description: 'CAREER UP STAGE様に掲載いただきました',
    details: [
      {
        text: '20代転職サイトおすすめランキング！前半後半や未経験・女性向けに比較',
        url: 'https://asiro.co.jp/media-career/156/',
      },
      {
        text: '未経験から営業職はやめとけ？実情や転職成功のカギを徹底解説',
        url: 'https://asiro.co.jp/media-career/2557/',
      },
      {
        text: '20代で高収入は狙える？おすすめの転職サイト10選を徹底解説',
        url: 'https://asiro.co.jp/media-career/3485',
      },

     
    ],
  },
  {
    no: '6',
    title: '2024/4/15',
    description: 'CAREER CLASS様に掲載いただきました',
    details: [
      {
        text: '営業職に強い転職エージェントおすすめ13選｜営業職に強い人材紹介会社の総合型と特化型をそれぞれ紹介',
        url: 'https://cocol.co.jp/career/sales/',
      },
      {
        text: 'CAREER CLASS様に掲載いただきました',
        url: 'https://cocol.co.jp/career/',
      },
     
    ],
  },
  {
    no: '1',
    title: '2024/4/4',
    description: 'Rs MEDIA様に掲載いただきました',
    details: [
      {
        text: '【営業職に強いおすすめ転職サイト＆エージェント13選】選び方や活用方法も紹介',
        url: 'https://www.rise-square.jp/contents/tensyoku/eigyou_agent.php',
      },
      {
        text: '20代におすすめの転職エージェント17選｜活用方法・利用者の口コミも紹介',
        url: 'https://www.rise-square.jp/contents/tensyoku/20dai_agent.php',
      },
     
    ],
  },
  { no: '2',
title: '2024/4/4',
description: 'IT業界ノート様に掲載いただきました',
details: [
  {
    text: 'IT営業への転職におすすめの転職エージェント7選【診断表つき】',
    url: 'https://it-sales-note.com/recruit-agency-for-it-sales/',
  },   
],
},
  {
    no: '3',
    title: '2024/2/21',
    description: 'Lumii Discover様に掲載いただきました',
    details: [
      {
        text: '国内の新たな企業・サービスを発見するWebメディア',
        url: 'https://lumii.co.jp/discover/',
      },
    ],
  },
  {
    no: '4',
    title: '2024/2/2',
    description: 'CAREER BIBLE様に掲載いただきました',
    details: [
      {
        text: '【徹底比較】転職エージェントおすすめ45選【2024】業界・職種別にも紹介！',
        url: 'https://nexer.co.jp/career-bible/domestic-company_jobchange/',
      },
      {
        text: '20代向けハイクラス層のおすすめ転職エージェント14選！求められるスキルとは？',
        url: 'https://nexer.co.jp/career-bible/high-class-20s/',
      },
      {
        text: '20代の平均年収や手取りは？大卒や高卒での違いは？男女別や年収アップのコツなども解説！',
        url: 'https://nexer.co.jp/career-bible/20-average-annual-income/',
      },
    ],
  },
  {
    no: '5',
    title: '2022/11/4',
    description:
      'きらぼし銀行アクセラレーションプログラム「KicSpace Accelerator」に採択されました！',
    details: [
      {
        text: '採択企業決定のお知らせ',
        url: 'https://kicspace.com/accelerator/information/366/',
      },
    ],
  },


];

function HomePage() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    // ニュースデータをステートにセット
    setNews(newsData);
  }, []);

  return (
    <div>
      <div className="body-content">
        <div className="image-text">
          <img
            src="/images/topsphere.png"
            alt="画像"
            className="scaled-image"
          />
          <p className="text-next-to-image">
            <span className="break-text">ルールを</span>
            <span className="text-change">変える。</span>
            <span className="break-on-small"></span>
            <span className="break-text">社会が</span>
            <span className="text-change">変わる。</span>
          </p>
        </div>
      </div>

      <div className="body-content">
        <div className="center-text">-SERVICE-</div>
        <div className="service-container">
          <div className="text-content">
            <p className="service-intro">
              1次面接通過レベルの
              <br />
              人材とだけ出会える
              <br />
              今までにない採用サービス
            </p>
            <p className="service-detail">
              登録ユーザー全てに共通1次面接と結果のスコアリングを実施。
              採用要件に合わせ合格のボーダー点数を設定するだけで１次面接通過レベルの人材のみから応募が届きます。
              OSUMITSUKIは時間のかかる書類選考と面接時間を大幅に削減できる業界唯一の採用サービスです。
              ※現在1都3県の営業職のみ対応しています
            </p>
            <a
              href="https://osumitsuki-b.studio.site/"
              className="more-button1"
            >
              もっと見る
            </a>
          </div>
          <div className="service-image">
            <img src="/images/back1.png" alt="Service Image" />
          </div>
        </div>

        <div className="recruit-section">
          <div className="center-textrecruit">-RECRUIT-</div>
          <p className="recruit-title">採用情報</p>
          <p className="recruit-main">
            PortRayでは今まで存在しなかったルールを共に創る仲間を募集しています。
            <br />
            新たなルールを創り出し今の社会を変えていきませんか？
          </p>
          <div className="recruit-text">
            <a href="#more-recruit" className="more-button2">
              現在は募集しておりません
            </a>
          </div>
        </div>

        <div className="media-section">
          <div className="center-text">-MEDIA-</div>
          <div className="box-container">
            <div className="box">
              <h2 className="youtubeTitle">転職系アニメチャンネル</h2>
              <img src="/images/YOutubeCH.png" alt="画像1の説明" />
              <p className="media-description">
                月間再生数約20万回。アニメ×エンタメ形式で転職活動のノウハウや情報・キャリアについて発信する完全オリジナルのアニメチャンネル。アニメーションは全て自社で制作しています。
              </p>
            </div>
            <div className="box">
              <h2 className="youtubeTitle">リアルな仕事1日密着</h2>
              <img src="/images/YOutubeCH2.png" alt="画像2の説明" />
              <p className="media-description">
                最高再生数90万超え。国内最大級の仕事1日密着動画メディア。オフィスワークから花屋・探偵・きこり・保育士など様々な仕事をNGなしでお届けしています。取材先の企業様も随時募集中。
              </p>
            </div>
          </div>
        </div>

        <div className="news-section">
          <div className="center-text">-NEWS-</div>
          <div className="news-container">
            {news.map((item) => (
              <div key={item.no} className="news-item">
                <div className="title-description-container">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
                {item.details &&
                  item.details.map((detail, index) => (
                    <div key={index} className="news-detail">
                      <a
                        href={detail.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {detail.text}
                      </a>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;

import React, { useState, useEffect } from 'react';
import './ContactForm.css';
import { firestore } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, []);

  const validateForm = () => {
    if (
      !formData.name ||
      !formData.company ||
      !formData.phone ||
      !formData.email ||
      !formData.message
    ) {
      return '全ての項目の入力は必須です。';
    }
    if (!/^\d+$/.test(formData.phone)) {
      return '電話番号は数値のみで入力してください。';
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      return 'メールアドレスの形式が正しくありません。';
    }
    return '';
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const error = validateForm();
    if (error) {
      setFormError(error);
      setIsSubmitting(false);
      return;
    }

    try {
      const docRef = await addDoc(collection(firestore, 'contacts'), formData);
      console.log('データ送信成功', docRef.id);

      setShowModal(true);
      setFormError('');
      setFormData({
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.error('エラー発生:', error);
      setFormError('送信中にエラーが発生しました。');
    } finally {
      setIsSubmitting(false);
    }
  };

  const closePopup = () => {
    setShowModal(false);
  };

  return (
    <div className="contact-form-container">
      <div className="logo">
        <img src="/images/PortRay_ロゴ.png" alt="ロゴ" />
      </div>
      <h2>Contact</h2>
      <p>
        弊社へのお問い合わせありがとうございます。
        <br />
        内容を確認次第ご連絡させていただきますので今しばらくお待ちください。
      </p>
      {formError && <p className="form-error">{formError}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">名前:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="company">会社名:</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">電話番号:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">メールアドレス:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">問い合わせ内容:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit" disabled={isSubmitting}>
          送信
        </button>
      </form>
      {showModal && (
        <div className="popup">
          <div className="modal-content">
            <p>
              お問い合わせありがとうございます。<br></br>
              担当者からのご連絡をお待ちください。
            </p>
            <button onClick={closePopup}>閉じる</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactForm;

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyCG0_1t5KU1KHoV_hpFJ7touEAZ3dxHNno',
  authDomain: 'homepage-894ad.firebaseapp.com',
  projectId: 'homepage-894ad',
  storageBucket: 'homepage-894ad.appspot.com',
  messagingSenderId: '335842912316',
  appId: '1:335842912316:web:3a7d9c879321230d7abb39',
  measurementId: 'G-7X40YMW8PD',
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const functions = getFunctions(app); // Functionsのインスタンスを取得

export { firestore, functions }; // functionsもエクスポート